// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-writing-tsx": () => import("./../../../src/pages/writing.tsx" /* webpackChunkName: "component---src-pages-writing-tsx" */),
  "component---src-templates-simple-tsx": () => import("./../../../src/templates/simple.tsx" /* webpackChunkName: "component---src-templates-simple-tsx" */)
}

